export default {
  id: {
    type: Number,
    visibility: false
  },
  type: {
    label: 'Tipo',
    type: String,
    options: [{
      id: 'reconocimiento_facial',
      name: 'Reconocimiento facial'
    }, {
      id: 'reconocimiento_placas',
      name: 'Reconocimiento de placas'
    }, {
      id: 'reconocimiento_vehiculos',
      name: 'Reconocimiento de vehículos'
    }, {
      id: 'reconocimiento_personas',
      name: 'Reconocimiento de personas'
    }, {
      id: 'reconoimiento_objetos',
      name: 'Reconocimiento de objetos'
    }],
  },
  name: {
    label: 'Nombre',
    type: String
  },
  uniqueId: {
    label: 'Identificador',
    type: String
  },
  _location: {
    label: 'Ubicación',
    type: String,
    location: true,
    fields: ['latitude', 'longitude']
  },
  longitude: {
    label: 'Longitud',
    type: Number,
    visibility: false
  },
  latitude: {
    label: 'Latitud',
    type: Number,
    visibility: false
  },
  address: {
    label: 'Dirección',
    type: String,
    textarea: true,
  },
  ipAddress: {
    label: 'Dirección IP',
    type: String
  },
  groupId: {
    label: 'Grupo',
    type: Number,
    optional: true,
    model: 'groups',
    module: 'lpr',
    autocomplete: false
  },
  attributes: {
    label: 'Atributos',
    type: Object,
    optional: true,
    attributes: true
  }
};
